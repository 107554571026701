<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <orders-modal
      ref="detailsModal"
      :selectedData="selectedData"
      isAdmin
      @GetList="GetList"
    />
    <MyDataTable
      title="سفارش های موجود"
      subtitle="مدیریت سفارش"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      deleteText=""
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>
          <small>
            {{ row.code }}
          </small>
        </td>
        <td>
          <small>
            {{
              row.user && row.user.first_name && row.user.first_name
                ? row.user.first_name + " " + row.user.last_name
                : "کاربر با شماره" + (row.user_id || "نامشخص")
            }}
          </small>
        </td>
        <td>
          <small>
            {{ row.payment_id }}
          </small>
        </td>
        <td>
          <v-chip small>{{ row.formatted_status }}</v-chip>
          <v-chip
            v-if="row.payment"
            small
            :color="row.payment.status !== 4 ? 'error' : 'success'"
          >
            {{ row.payment.formatted_status }}
          </v-chip>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import OrdersModal from "../../../../components/Panel/OrdersModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    OrdersModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/orders/index", {
        params: {
          with: ["orderProduct", "user", "payment", "discount"],
          page: this.$route.query?.page || 1,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/ordersmanager/edit/" + item.id,
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/orders/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/orders/index",
      pathParams: {
        with: ["orderProduct", "user"],
      },
      headers: [
        {
          text: "کد",
          align: "start",
          value: "code",
        },
        { text: "توسط", value: "user_id" },
        { text: "شناسه پرداخت", value: "payment_id" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
